@charset "UTF-8";

@font-face {
    font-family: "klouds-font";
    src:
            url("./../../assets/fonts/klouds-font.woff") format("woff"),
            url("./../../assets/fonts/klouds-font.ttf") format("truetype"),
            url("./../../assets/fonts/klouds-font.svg#klouds-font") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}


[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'klouds-font', serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'klouds-font', serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-developer-tools-x-ray:before {
    content: "\e927";
}
.icon-security-identity-compliance-secrets-manager:before {
    content: "\e933";
}
.icon-containers-elastic-container-registry:before {
    content: "\e934";
}
.icon-app-integration-simple-queue-service:before {
    content: "\e935";
}
.icon-compute-ec2:before {
    content: "\e932";
}
.icon-security-identity-compliance-certificate-manager:before {
    content: "\e931";
}
.icon-storage-backup:before {
    content: "\e928";
}
.icon-networking-content-delivery-route-53:before {
    content: "\e929";
}
.icon-storage-simple-storage-service:before {
    content: "\e92f";
}
.icon-management-governance-cloudformation:before {
    content: "\e930";
}
.icon-klouds-logo:before {
    content: "\e926";
}
.icon-app-integration-simple-notification-service:before {
    content: "\e900";
}
.icon-networking-content-delivery-cloudfront:before {
    content: "\e901";
}
.icon-security-identity-compliance-cognito:before {
    content: "\e902";
}
.icon-compute-elastic-beanstalk:before {
    content: "\e903";
}
.icon-database-dynamodb:before {
    content: "\e904";
}
.icon-storage-elastic-file-system:before {
    content: "\e905";
}
.icon-storage-fsx:before {
    content: "\e906";
}
.icon-storage-simple-storage-service-glacier:before {
    content: "\e907";
}
.icon-security-identity-compliance-key-management-service:before {
    content: "\e908";
}
.icon-security-identity-compliance-identity-and-access-management:before {
    content: "\e909";
}
.icon-compute-lambda:before {
    content: "\e90a";
}
.icon-database-rds:before {
    content: "\e90b";
}
.icon-developer-tools-tools-and-sdks:before {
    content: "\e90c";
}
.icon-app-integration-eventbridge:before {
    content: "\e90d";
}
.icon-management-governance-cloudwatch:before {
    content: "\e90e";
}
.icon-app-integration-api-gateway:before {
    content: "\e90f";
}
.icon-networking-content-delivery-elastic-load-balancing:before {
    content: "\e910";
}
.icon-analytics-analytics:before {
    content: "\e911";
}
.icon-application-integration-application-integration:before {
    content: "\e912";
}
.icon-ar-vr-ar-vr:before {
    content: "\e913";
}
.icon-aws-cost-management-aws-cost-management:before {
    content: "\e914";
}
.icon-blockchain-blockchain:before {
    content: "\e915";
}
.icon-business-applications-business-applications:before {
    content: "\e916";
}
.icon-compute-compute:before {
    content: "\e917";
}
.icon-containers-containers:before {
    content: "\e918";
}
.icon-customer-enablement-customer-enablement:before {
    content: "\e919";
}
.icon-database-database:before {
    content: "\e91a";
}
.icon-developer-tools-developer-tools:before {
    content: "\e91b";
}
.icon-end-user-computing-end-user-computing:before {
    content: "\e91c";
}
.icon-front-end-web-mobile-front-end-web-mobile:before {
    content: "\e91d";
}
.icon-game-tech-game-tech:before {
    content: "\e91e";
}
.icon-internet-of-things-internet-of-things:before {
    content: "\e91f";
}
.icon-machine-learning-machine-learning:before {
    content: "\e920";
}
.icon-management-governance-management-governance:before {
    content: "\e921";
}
.icon-media-services-media-services:before {
    content: "\e922";
}
.icon-migration-transfer-migration-transfer:before {
    content: "\e923";
}
.icon-networking-content-delivery-networking-content-delivery:before {
    content: "\e924";
}
.icon-quantum-technologies-quantum-technologies:before {
    content: "\e925";
}
.icon-robotics-robotics:before {
    content: "\e92a";
}
.icon-satellite-satellite:before {
    content: "\e92b";
}
.icon-security-identity-compliance-security-identity-compliance:before {
    content: "\e92c";
}
.icon-serverless-serverless:before {
    content: "\e92d";
}
.icon-storage-storage:before {
    content: "\e92e";
}
